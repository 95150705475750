import Vue from "vue";
import App from "./App.vue";
import Loading from "./components/Loading.vue";
import router from "./router";
import store from "./store";
import IdleVue from "idle-vue";
import vuetify from "./plugins/vuetify";
import { authService } from "pelotonplatformcommon";

const idleEventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: idleEventsHub,
  idleTime: process.env.VUE_APP_IDLE_TIMEOUT_MINUTES * 60000,
  store: store,
});

// start by rendering just the loading component
const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(Loading),
}).$mount("#app");

const currentUrl = new URL(window.location.href);
const path = currentUrl.pathname;
const queryParams = currentUrl.searchParams;
const invite = queryParams.get('invitation');

async function checkAuthentication() {
  // Accept invitation links from Auth0 organization invitations.
  if (invite) {
    return await authService.loginWithRedirect({
      authorizationParams: {
        invitation: invite,
      },
    });
  }
  else if (path === '/authorize') {
    try {
      await authService.handleRedirectCallback();

      // Retrieve the originally requested route (if saved) or default to '/'
      const targetRoute = localStorage.getItem('savedRoute') || '/';
      localStorage.removeItem('savedRoute');
      router.replace(targetRoute);

      // Render the main app component after successful authentication
      app.$options.render = h => h(App);
      app.$forceUpdate(); // Force re-render with the main app
    } catch (error) {
      await authService.loginWithRedirect();
    }
  } else {
    // For other routes, initiate the login flow
    // Save the current path for redirecting after successful login
    localStorage.setItem('savedRoute', window.location.pathname);
    
    await authService.loginWithRedirect();
  }
}

checkAuthentication();
