<template>
  <v-overlay
    class="d-flex align-center justify-center fill-height"
    z-index="999"
    opacity="1"
    color="tertiary"
  >
    <div
      style="transform: scale(1, 1.5) translateY(-1px)"
      class="d-flex flex-column align-center"
    >
      <span
        style="
          font-family: Impact;
          letter-spacing: 1px;
          font-size: 38pt;
          text-transform: lowercase;
        "
        :class="$vuetify.theme.dark ? 'white--text' : 'primary--text'"
      >
        pe<span class="accent--text">l</span>oton
      </span>
    </div>

    <div class="my-2"></div>

    <v-progress-linear
      color="primary"
      indeterminate
      v-if="loading"
    ></v-progress-linear>

    <div class="my-2"></div>
    <div class="d-flex flex-column align-center" v-if="text">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            small
            v-on="on"
            v-bind="attrs"
            @click="retry"
            >{{ text }}</v-btn
          >
        </template>

        <span>{{ help }}</span>
      </v-tooltip>
      <small class="black--text pt-2">{{ help }}</small>
    </div>
  </v-overlay>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    text: String || undefined,
    help: String || undefined,
    retry: Function || undefined,
    loading: Boolean,
  },
});
</script>