import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [
    {
      path: "/authorize",
    },
    {
      path: "/",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/Index.vue"),
    },
    {
      path: "/organization",
      name: "Organization",
      component: () =>
        import(
          /* webpackChunkName: "organization" */ "@/views/OrganizationChooser.vue"
        ),
    },
  ],
});

router.beforeEach((to, from, next) => {
  localStorage.setItem("peloton.platform.apps.route.from", from.path);
  localStorage.setItem("peloton.platform.apps.route.to", to.path);
  next();
});

export default router;
