import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#5A6E8C',
                secondary: {
                    base: colors.grey.lighten3,
                    darken1: "F6F6F6"
                },
                tertiary: '#FFF',
                accent: '#6EBE4A',
            },
            dark: {
                primary: '#0065A4',
                secondary: {
                    base: '#3E4149',
                    darken1: '#34373E'
                },
                tertiary: '#2B2E33',
                accent: '#6BC149',
                error: '#CC4E45'
            }
        },
        options: {
            customProperties: true
        }
    },
    breakpoint: {
        mobileBreakpoint: 'sm'
    }
});
