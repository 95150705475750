<script lang="ts">
import Vue from "vue";
import { HttpUtils, getUserFromToken, IBasicOrganization, IOrganizationRegion, axiosInstance, PlatformInternalService, PELOTON_CONFIGURATION, IUser, authService, isPlatformAdmin } from "pelotonplatformcommon";
import jwtDecode from "jwt-decode";
import { AxiosError } from 'axios';

interface ErrorResponse {
  message?: string;
}

export default Vue.extend({
  name: "App",
  data() {
    return {
      ready: false,
      loading: false,
      maxHeight: 0,
      progress: "",
      help: "",
      broadcastChannel: null as BroadcastChannel | null,
    };
  },
  computed: {
    platformInternalApi(): PlatformInternalService {
      return new PlatformInternalService(
        PELOTON_CONFIGURATION.uris.platformInternalApi,
        this.$store.getters.activeOU,
        axiosInstance,
        this.$store.getters.region
      );
    },
  },
  watch: {
    "$store.state.organization": async function (value) {
      await this.updateProducts()
    },
    "$store.state.idleVue.isIdle": function () {
      if (this.$store.state.idleVue.isIdle) {
        console.log("Idle timeout");
        this.logout();
      }
    },
    "$store.state.region": function (value) {
      this.platformInternalApi.setRegion(value);
    },
    "$store.state.activeOU": function (value) {
      this.platformInternalApi.setOu(value);
    },
  },
  methods: {
    async setup() {
      // Set saved themeing
      this.$vuetify.theme.dark = false;

      // Make a repeating task to calculate the max height anything below the
      // menu can be resized accordingly.
      setInterval(() => (this.maxHeight = window.innerHeight - 52));
    },
    async updateProducts() {
      this.ready = false
      try {
        this.$store.commit("set.products", await this.platformInternalApi.getUserProducts());
        this.ready = true
      }
      catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          if (axiosError.response.status === 400) {
            const errorData = axiosError.response.data as ErrorResponse;
            let errorMessage = "Bad Request (400)";
            if (errorData && errorData.message) {
              errorMessage = errorData.message;
            } else if (errorData) {
              errorMessage = JSON.stringify(errorData);
            }
            this.showUnauthorized(errorMessage);
          } else {
            this.showUnauthorized(axiosError.message);
          }
        } else if (axiosError.request) {
          this.showUnauthorized("No response from server.");
        } else {
          this.showUnauthorized("Error in setting up the request.");
        }
      }

    },
    showUnauthorized(message: string) {
      this.progress = "Unauthorized";
      this.help = message;
      this.loading = false;
    },
    async setupUserOrganization() {
      this.ready = false;
      this.loading = true;

      const orgRegion = HttpUtils.getOrganizationRegionCookie(HttpUtils.PlatformCookieName);
      if (orgRegion && orgRegion.ou) {
        this.$store.commit("set.activeOU", orgRegion.ou);
      }

      try {
        // the region value will be set to the last OU logged into
        const token = await authService.getTokenSilently();
        const user = await authService.getUser();
        const decodedToken = jwtDecode(token);
        const profile = getUserFromToken(decodedToken);
        this.$store.commit("set.user", user);
        this.$store.commit("set.profile", profile);

        // Before making our first API call we need the region set so we hit the API in the region closest
        const defaultOrg = this.getStartOrganization(profile);
        if (defaultOrg == undefined) {
          this.firstLoginCheck();
          return;
        }

        this.$store.commit("set.region", defaultOrg.region);
        this.$store.commit("set.activeOU", defaultOrg.ou);

        this.$store.commit("set.organizations", await this.platformInternalApi.get("organization"));
        const organization = this.$store.getters.organizations.find((organization: IBasicOrganization) => organization.name === defaultOrg.ou)
        if (organization != undefined) {
          this.$store.commit("set.organization", organization)
        }
        else {
          throw new Error('No access to this organization');
        }
      } catch (err) {
        this.showUnauthorized(`${err}`);
      }
    },
    getStartOrganization(profile: IUser): IOrganizationRegion | undefined {
      const availableOrgs: IOrganizationRegion[] = profile.app_metadata.regions
      let defaultOrg: IOrganizationRegion = availableOrgs[0]
      const lastOuName: string = this.$store.getters.activeOU;
      const lastOrg = availableOrgs.find((org) => org.ou === lastOuName)
      const isAdmin = isPlatformAdmin(this.$store.getters.profile);
      if (isAdmin && this.$store.getters.activeOU && this.$store.getters.region) {
        return {
          ou: this.$store.getters.activeOU,
          region: this.$store.getters.region
        };
      }
      else if (lastOrg) {
        return lastOrg;
      }
      else {
        return defaultOrg;
      }
    },
    async firstLoginCheck() {
      try {
        await this.platformInternalApi.firstLoginCheck()
      }
      catch (err) {
        this.showUnauthorized('You do not have access to this organization.');
      }
    },
    async logout() {
      this.broadcastChannel?.postMessage("logout");
      this.broadcastChannel?.close();
      this.broadcastChannel = null;
      this.identityServerLogout();
    },
    async identityServerLogout() {
      const currentOU: string = this.$store.getters.activeOU;
      localStorage.clear();
      sessionStorage.clear();
      // now thats cleared we do want to 'remember' the last OU so that it can be used to log back in
      this.$store.commit("set.activeOU", currentOU);
      authService.logout();
    },
    setupBroadCastChannel() {
      this.broadcastChannel = new BroadcastChannel("peloton_channel");
      const thisContext = this;
      this.broadcastChannel.onmessage = (event) => {
        if (event.data === "logout") {
          thisContext.logout();
        }
      };
    },
  },
  async beforeMount() {
    await this.setup();
    await this.setupUserOrganization();
    this.setupBroadCastChannel();
  },
  beforeDestroy() {
    this.broadcastChannel?.close();
  },
  components: {
    Menu: () => import("@/components/Menu.vue"),
    Loading: () => import("@/components/Loading.vue"),
  },
});
</script>

<template>
  <v-app class="secondary">
    <Menu v-if="ready" :platformInternalApi="platformInternalApi" @logout="logout" />
    <Loading v-if="!ready" :loading="loading" :text="progress" :help="help" :retry="() => logout()" />

    <v-main v-if="ready" class="main-view">
      <router-view v-if="ready" :style="{
      overflow: 'auto',
      position: 'relative',
    }" :maxHeight="maxHeight" :platformInternalApi="platformInternalApi" :loading="loading"
        :class="$vuetify.theme.dark ? 'tertiary' : 'white'"></router-view>
    </v-main>
  </v-app>
</template>

<style>
/* Remove all overflow because it'll be handled by the different views */
html,
body {
  overflow-y: hidden !important;
}

/* I find background transitions to be jerky and annoying. Disable them. */
* {
  transition: 0s !important;
}

.main-view {
  background-color: #5a6e8c;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #848484 transparent;
}

*:hover {
  scrollbar-color: #a0a0a0 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #848484;
  border-radius: 10px;
  border: 2px solid white;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}
</style>