
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { IUserProduct } from '@/custom'
import { IUser as IPelotonUser, IBasicOrganization, isOrganizationAdmin, PELOTON_CONFIGURATION } from 'pelotonplatformcommon'
import { User } from "@auth0/auth0-spa-js";

Vue.use(Vuex)

const persist = new VuexPersist({ storage: localStorage })

interface IPlatformState {
	organization: (IBasicOrganization | null);
	organizations: IBasicOrganization[];
	user: (User | null);
	profile: (IPelotonUser | null); 
	region: string;
	activeOU: (string | null);
	products: (IUserProduct[] | null);
	lastNotificationCheck: (Date | null);
}

export default new Vuex.Store<IPlatformState>({
	state:  {
		organization: {} as IBasicOrganization,
		organizations: [] as IBasicOrganization[],
		user: null,
		profile: null as (IPelotonUser | null),
		region: PELOTON_CONFIGURATION.defaultRegion,
		activeOU: null as (string | null),
		products: null as (IUserProduct[] | null),
		lastNotificationCheck: null as (Date | null)
	},
	mutations: {
		'set.organization': (state, payload) => {
			state.activeOU = payload.name
			state.region = payload.region ?? PELOTON_CONFIGURATION.defaultRegion
			state.organization = payload
		},
		'set.organizations': (state, payload) => state.organizations = payload,
		'set.user': (state, payload) => state.user = payload,
		'set.profile': (state, payload) => state.profile = payload,
		'set.region': (state, payload) => {  state.region = payload },
		'set.activeOU': (state, payload) => {  state.activeOU = payload },
		'set.products': (state, payload) => state.products = payload,
		'set.lastNotificationCheck': (state, payload) => {  state.lastNotificationCheck = payload },
	},
	getters: {
		organization: state => state.organization,
		organizations: state => state.organizations,
		user: state => state.user,
		profile: state => state.profile,
		region: state => state.region,
		activeOU: state => state.activeOU,
		products: state => state.products,
		hasApps: state => (state.products?.length ?? 0) > 0,
		isActiveOrganizationAdmin(state) {
			if (state.activeOU === null || state.profile === null) { return false }

			return isOrganizationAdmin(state.activeOU, state.profile)
		},
		lastNotificationCheck: state => state.lastNotificationCheck
	},
	strict: false,
	modules: {
		components: {
			namespaced: true,
		}
	},
	plugins: [persist.plugin]
})
